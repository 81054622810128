<template>
    <BaseLayout>
		<section class="ol-product ol-##" style="margin-bottom:50px;">
			<div class="ol-wrapper ol-wrapper--large">
				<div class="title-div-content">
                    <h2 class="heading-content">Keranjang Belanja</h2>
                </div>
				<div class="ol-alert ol-alert--info" v-if="outOfStock"> Terdapat Stok Produk Kosong !</div>
				<div class="ol-cart" >
					<div v-if="variants.length > 0" class="ol-cart-list" id="keranjang">
						<div class="shopping-cart">
							<!-- <div class="column-labels">
								<label class="product-check">Check</label>
								<label class="product-image">Pilih Semua</label>
								<label class="product-details">Produk</label>
								<label class="product-price">Harga</label>
								<label class="product-quantity">Jumlah</label>
								<label class="product-removal">Hapus</label>
								<label class="product-line-price">Total</label>
							</div> -->
							<div class="product">
								<div class="product-check">
									<input type="checkbox" id="checkAll" class="checkbox-cart" :checked="checkedAll" @click="checkAll">
								</div>
								<div class="product-details">
									<strong style="color:#6C7C93">Pilih Semua</strong>
								</div>
							</div>
							<div v-for="(item,index) in variants" :key="index">
								<div class="product">
									<div class="product-check">
										<input type="checkbox" class="checkbox-cart checkbox-product" :checked="item.is_selected"  @click="checkProduct(item)">
									</div>
									<div class="product-image">
										<router-link :to="'/product/'+item.slug">
											<img v-if="item.r_uni_product_image" :src="item.r_uni_product_image.image_url" :alt="item.name_long" :title="item.name_long">
											<img v-else :src="noImage()" :alt="item.name_long" :title="item.name_long">
											
										</router-link>
									</div>
									<div class="product-details">
										<div class="product-title">
											<router-link :to="'/product/'+item.slug">
												<h3 class="ol-cart-list__body-item-desc-name two-lines " style="color: #141414;font-weight: 500;">
													{{item.name_long}}
												</h3>
												<span v-if="!item.bundle_summary" class="ol-cart-list__body-item-desc-type">
													<!-- {{item.variant}} -->
												</span>
												<span v-else class="ol-cart-list__body-item-desc-type">
													<span v-for="detail in item.bundle_summary" :key="detail.id+'detail-bundle'"> {{detail.name_short}} <b>x</b> {{detail.quantity}}<br></span>	
												</span>
											</router-link>
											
										</div>
										<span v-if="item.r_uni_product.variant_1_name && item.r_uni_product.variant_2_name">
											<p class="product-description"><b>{{item.r_uni_product_get_po ? 'Pre-order -' : ''}}</b> {{item.r_uni_product.variant_1_name}} : {{item.variant}}, {{item.r_uni_product.variant_2_name}} : {{item.color}}</p>
										</span>
										<span v-else>
											<p class="product-description"><b>{{item.r_uni_product_get_po ? 'Pre-order -' : ''}}</b> Varian : {{item.variant}}&nbsp;</p>
										</span>
										<p class="product-description" v-if="item.is_reedem == 1">
											<span style="    background: linear-gradient(275.14deg, #B24ECB 19.66%, #722785 113.02%);border-radius: 4px;color: white;padding: 4px 8px;font-size: 10px;"> Tebus Murah </span>
										</p>
									</div>
									<div class="product-price">
										<span class="ol-home-product__content-item-list--item-price-real display-price-desktop" v-if="item.r_uni_product_variant_prices.more_discount && item.r_uni_product_variant_prices.more_discount != '0%'">{{item.r_uni_product_variant_prices.ho_normal_rp}}</span>
										<small class="ol-home-product__content-item-list--item-price-discount display-price-desktop">
											{{ item.valid_price_rp }}
										</small>
										<small class="ol-home-product__content-item-list--item-price-discount display-price-mobile">
											{{ item.subtotal_rp }}
										</small>
									</div>
									<div class="product-quantity">
										<div v-if="item.is_reedem != 1" class="ol-input-qty"  >
											<span name="" class="ol-input-qty__btn decrease" @click="min(item)">-</span>
											<input type="text" class="ol-input" :value="item.quantity" disabled>
											<span class="ol-input-qty__btn increase" @click="plus(item)">+</span>
										</div>
										<div v-else  class="ol-input-qty">
											<input type="text" class="ol-input" :value="item.quantity" disabled>
										</div>
										<br>
										<div v-if="!loadingCart && !item.bundle_summary">
											<small v-if="item.quantity <= item.valid_stock" class="ol-home-product__content-item-list--item-price-discount" style="color: #fb5353;font-size: 80%;">Stok tersisa &lt; {{ item.valid_stock }} </small>
											<small v-else class="ol-home-product__content-item-list--item-price-discount" style="color: #fb5353;font-size: 80%;">Stok kosong! </small>
										</div>
									</div>
									<div class="product-removal">
										<!-- <button v-if="!item.r_uni_product_get_free"  class="remove-product"  @click="updateVariant(item)">
											<i class="fa fa-cart-arrow-down" style="color:#D2D2D2;"></i>
										</button> -->
										<button class="remove-product"  @click="removeVariant(item)">
											<i class="fa fa-trash"></i>
										</button>
									</div>
									<div v-if="loadingCart" class="product-line-price ol-home-product__content-item-list--item-price-discount"><SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /></div>
									<div v-else class="product-line-price ol-home-product__content-item-list--item-price-discount" style="color: #4E6CB1;font-size: 16px;">{{item.subtotal_rp}}</div>	
								</div>
								<div v-if="item.r_uni_product_get_free" class="product">
									<div class="product-check">
										&nbsp;
									</div>
									<div class="product-image">
										<router-link :to="'/product/'+item.r_uni_product_get_free.r_uni_product_variants.slug">
											<img v-if="item.r_uni_product_get_free.r_uni_product_variants.r_uni_product_image" :src="item.r_uni_product_get_free.r_uni_product_variants.r_uni_product_image.image_url" :alt="item.r_uni_product_get_free.r_uni_product_variants.name_long" :title="item.r_uni_product_get_free.r_uni_product_variants.name_long">
												<img v-else :src="noImage()" :alt="item.r_uni_product_get_free.r_uni_product_variants.name_long" :title="item.r_uni_product_get_free.r_uni_product_variants.name_long">
										</router-link>
									</div>
									<div class="product-details">
										<div class="product-title">
											<router-link :to="'/product/'+item.slug">
												<h3 class="ol-cart-list__body-item-desc-name two-lines" style="color: #141414;font-weight: 500;">
													{{item.r_uni_product_get_free.r_uni_product_variants.name_long}}
												</h3>
											</router-link>
										</div>
										<p class="product-description">
											<b> ({{item.r_uni_product_get_free.free_type}}) </b>
											<span style="color:grey"> dari {{item.name_short}} </span>
										</p>
									</div>
									<div class="product-price">
										<span class="ol-home-product__content-item-list--item-price-real">{{item.r_uni_product_get_free.r_uni_product_variants.r_uni_product_variant_prices.ho_normal_rp}}</span>
										<small class="ol-home-product__content-item-list--item-price-discount"> Rp 0 </small>
									</div>
									<div class="product-quantity">
										<div class="ol-input-qty">
											<span name="" class="ol-input-qty__btn decrease" >-</span>
											<input type="text" class="ol-input" :value="item.quantity" disabled>
											<span class="ol-input-qty__btn increase">+</span>
										</div><br>
										<div v-if="!loadingCart">
											<small v-if="item.quantity <= item.r_uni_product_get_free.r_uni_product_variants.valid_stock" class="ol-home-product__content-item-list--item-price-discount" style="color: #fb5353;font-size: 80%;">Stok tersisa &lt; {{ item.r_uni_product_get_free.r_uni_product_variants.valid_stock }} </small>
											<small v-else class="ol-home-product__content-item-list--item-price-discount" style="color: #fb5353;font-size: 80%;">Stok kosong! </small>
										</div>
									</div>
									<div class="product-removal">
										&nbsp;
									</div>
									<div v-if="loadingCart" class="product-line-price ol-home-product__content-item-list--item-price-discount"><SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /></div>
									<div v-if="!loadingCart" class="product-line-price ol-home-product__content-item-list--item-price-discount" style="color: #4E6CB1;font-size: 16px;">Rp 0</div>
								</div>
								
								<div v-if="item.is_get_combo == 1" class="product">
									
									<div class="product-check">
										&nbsp;
									</div>
									<div class="product-image">
										<router-link :to="'/product/'+item.r_uni_product_get_combo.r_uni_product_variants.slug">
											<img v-if="item.r_uni_product_get_combo.r_uni_product_variants.r_uni_product_image" :src="item.r_uni_product_get_combo.r_uni_product_variants.r_uni_product_image.image_url" :alt="item.r_uni_product_get_combo.r_uni_product_variants.name_long" :title="item.r_uni_product_get_combo.r_uni_product_variants.name_long">
												<img v-else :src="noImage()" :alt="item.r_uni_product_get_combo.r_uni_product_variants.name_long" :title="item.r_uni_product_get_combo.r_uni_product_variants.name_long">
										</router-link>
									</div>
									<div class="product-details">
										<div class="product-title">
											<router-link :to="'/product/'+item.slug">
												<h3 class="ol-cart-list__body-item-desc-name two-lines" style="color: #141414;font-weight: 500;">
													{{item.r_uni_product_get_combo.r_uni_product_variants.name_long}}
												</h3>
											</router-link>
										</div>
										<p class="product-description">
											<b> ({{item.r_uni_product_get_combo.free_type}}) </b>
											<span style="color:grey"> dari {{item.name_short}} </span>
										</p>
									</div>
									<div class="product-price">
										<span class="ol-home-product__content-item-list--item-price-real">{{item.r_uni_product_get_combo.r_uni_product_variants.r_uni_product_variant_prices.ho_normal_rp}}</span>
										<small class="ol-home-product__content-item-list--item-price-discount"> {{item.r_uni_product_get_combo.combo_price_rp}} </small>
									</div>
									<div class="product-quantity">
										<div class="ol-input-qty">
											<span name="" class="ol-input-qty__btn decrease" >-</span>
											<input type="text" class="ol-input" :value="item.quantity" disabled>
											<span class="ol-input-qty__btn increase">+</span>
										</div><br>
										<div v-if="!loadingCart">
											<small v-if="item.quantity <= item.r_uni_product_get_combo.r_uni_product_variants.valid_stock" class="ol-home-product__content-item-list--item-price-discount" style="color: #fb5353;font-size: 80%;">Stok tersisa &lt; {{ item.r_uni_product_get_combo.r_uni_product_variants.valid_stock }} </small>
											<small v-else class="ol-home-product__content-item-list--item-price-discount" style="color: #fb5353;font-size: 80%;">Stok kosong! </small>
										</div>
									</div>
									<div class="product-removal">
										&nbsp;
									</div>
									<div v-if="loadingCart" class="product-line-price ol-home-product__content-item-list--item-price-discount"><SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /></div>
									<div v-if="!loadingCart" class="product-line-price ol-home-product__content-item-list--item-price-discount" style="color: #4E6CB1;font-size: 16px;">
										{{toRp(item.quantity * parseInt(item.r_uni_product_get_combo.combo_price))}}
									</div>
								</div>
							</div>
						</div>
						<div v-if="reedem.length > 0" class="ol-product-content ol-product-related add-fix"  >
							<h2 class="ol-section-title">
								<span>Tambah Promo Tebus Murah</span>
								<i></i>
							</h2>
							<ul v-if="reedem.length > 0" class="card-reedems" style="margin-bottom:16px;">
								<li class="card-reedem" v-for="reedem in reedem" :key="'reedem-'+reedem.id">
									<div class="row no-gutters">
										<div class="col-4" style="padding:4px;">
											<img :src="reedem.r_uni_product_variants_main.r_uni_product_image.image_url" width="100%">
										</div>
										<div class="col-8">
											<div class="card-reedem-min-price">Min. Pembelian {{ reedem.min_price_rp }}</div>
											<h3 class="ol-home-product__content-item-list--item-title">
												{{ reedem.r_uni_product_variants_main.name_long }}
											</h3>
											<div class="flex-card-product">
												<div class="price-discount-card-product">{{ reedem.price_normal_reedem_rp }}</div>
												<div class="box-discount-card-product">
													<div class="discount-card-product">{{ reedem.discount_reedem }}</div>
												</div>
											</div>
											<div class="row no-gutters">
												<div class="col-6 price-card-product">
													{{ reedem.price_reedem_rp }}
												</div>
												<div class="col-6" style="text-align:right;">
													<button  @click="tebusMurah(reedem)" type="button" class="ol-btn" style="color: #C13B37;background: #FCF3F2;padding:4px 8px;border-radius:4px;box-shadow:0 2px 3px rgba(0, 0, 0, 0);" >Tambah </button>
													
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
						
						<div style="min-height:156px;display:block;    background-color: #F6F7F9;padding: 8px;border-radius: 8px;margin-bottom:16px">
							<div class="ol-cart-list__footer-item">
								
								<div>
									<span style="margin-left:0px;font-weight: 700;color: #364B7B;">Tambah Catatan </span>
									<div style="margin-top:8px">
										<input v-model="catatan" type="text" name="catatan" value="" class="ol-input" placeholder="Tulis Pesan (Opsional)" style="background-color: #EAEDF0;">
									</div>
								</div>
							</div>
							<div class="ol-cart-list__footer-item">
								<dl class="ol-cart-list__footer-item-total">
									<dt>Total</dt>
										<dd v-if="loadingCart" class="sub_total" style="margin-bottom:18px;"> <SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /> </dd>
										<dd v-if="!loadingCart" class="sub_total">{{this.grandtotalNormal}}</dd>
									<div style="clear: both;"></div>
									<dt>Diskon Produk</dt>
										<dd v-if="loadingCart" class="sub_total" style="margin-bottom:18px;"> <SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /> </dd>
										<dd v-if="!loadingCart" class="sub_total">-{{this.grandtotalHemat}}</dd>
									<div style="clear: both;"></div>
									<hr>
									<dt><strong style="font-size:16px;font-weight: 800;">Jumlah Total</strong></dt>
										<dd v-if="loadingCart" class="sub_total" style="margin-bottom:18px;"> <SkeletonLoader style="height:20px;width:100px;float:right;" corner="5" /> </dd>
										<dd v-if="!loadingCart" class="sub_total"><strong style="font-size:16px;">{{toRp(this.grandtotal)}}</strong></dd>
								</dl>
								<a :href="'/checkout'" :class="!loadingCart && checkedSome ? 'ol-btn':'ol-btn ol-btn-disable' " style="margin-top:4px;font-weight: 700;font-size:16px;" type="button">
									Checkout Sekarang&emsp;<i class="ol-fa fas fa-arrow-right"></i>
								</a>
							</div>
						</div>
					</div>
					<div v-else class="ol-cart-floating__empty">
						<figure class="ol-cart-floating__empty">
							<img :srcset="assets('empty-cart.svg')" alt="Keranjang Belanja" title="Keranjang Belanja" />
						</figure>

						<h4 class="ol-cart-floating__empty-label">
							Belum Ada Produk
						</h4>
						<router-link to="/products/q=" class="ol-btn">Belanja Sekarang</router-link><br>
					</div>
					<div v-if="recommended.length > 0" class="ol-product-content ol-product-related add-fix">
						<h2 class="ol-section-title">
							<span>Rekomendasi</span>
							<i></i>
						</h2>
						<ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingRecommended"/>
						<ProductList :products="recommended" v-if="!loadingRecommended" /> 
					</div>

					<div v-if="lastSeen.length > 0" class="ol-product-content ol-product-related add-fix">
						<h2 class="ol-section-title">
							<span>Dari pencarianmu</span>
							<i></i>
						</h2>
						<ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingLastSeen"/>
						<CarouselProduct :products="lastSeen" v-if="!loadingLastSeen" carouselKey="lastseen" /> 
					</div>
				</div>
			</div>
		</section>
		<Popup title="Ubah Varian Produk" id="updateVariant" >
			
			<div class="row" >
				<div class="col-12">
					<h1 class="ol-cart-list__body-item-desc-name " style="font-size:16px;" id="name-product"> - </h1>
					<small class="price_barang ol-home-product__content-item-list--item-price-discount" style="margin-right:8px" id="price-product">
					</small>
					<div class="break-16"></div>
				</div>
				<div class="col-6">
					<div class="ol-input-box ol-input-box--full">
						<label class="ol-input-label">Pilih Variant</label>
						<select id="cities" class="ol-input ol-input-select select2">
							<option>Blue</option>
							<option>Blue</option>
						</select>
					</div>
				</div>
				<div class="col-6">
					<!-- <div class="ol-input-box ol-input-box--full">
						<label class="ol-input-label">Pilih Variant</label>
						<select id="cities" class="ol-input ol-input-select select2">
							<option>Blue</option>
							<option>Blue</option>
						</select>
					</div> -->
				</div>
				<div class="col-12">
					<button  type="button" class="ol-btn" style="width:100%;"> Konfirmasi </button>
				</div>
			</div>
		</Popup>
    </BaseLayout>
</template>

<script>
import helpme from '@/util/helpme'
import { mapGetters, mapActions, mapMutations } from "vuex";
import BaseLayout from '@/components/landing/BaseLayout.vue'
import SkeletonLoader from '@/components/loading/skeletonLoader'
import ProductList from "@/components/product/ProductList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import CarouselProduct from '@/components/home/CarouselProduct.vue'
import Popup from '@/components/popup/Popup.vue'
export default {
    name:"Cart",
    components:{
        BaseLayout,
		SkeletonLoader,
		ProductList,
        ProductLoader,
        CarouselProduct,
		Popup,
    },
	computed:{
        ...mapGetters("auth", ["authenticated"]),
		...mapGetters("transaction/cart", ["variants","grandtotal","reedem","outOfStock","note","grandtotalHemat","grandtotalNormal","checkReedem","checkMinReedem"]),
		...mapGetters("product/detail", ["recommended"]),
        ...mapGetters('profile/product', ['lastSeen']),
		checkedAll() {
			return _.every(this.variants, "is_selected");
		},
		checkedSome() {
			// ? cek apakah ada produk yg dicentang dan apakah produk yg dicentang tidak melebihi jumlah yg dipilih user
			return _.some(this.variants, "is_selected") && _.every(_.filter(this.variants, "is_selected"), function(v) {return v.stock_available >= v.quantity});
		},
	},
	data(){
        return {
			catatan: "",
            loadingCart: false,
			loadingAllCart: false,
			loadingRecommended:true,
			loadingLastSeen:true,
			queue:null,
        }
    },
	methods:{
		...mapActions("transaction/cart", ["checkStock","save", "get","fetchReedem"]),
        ...mapActions('profile/product', ['saveProductHistory','fetchLastSeen']),
		async tebusMurah(reedem) {
            if(!this.authenticated) {
                this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }});
                return;
            }

			if(this.checkReedem == 1){
				
				Swal.fire({
					title: "Peringatan!",
					text: "Produk tebus murah maksimal 1 Produk !",
					icon: "warning"
				});
				return;
			}
			if(this.grandtotal >= reedem.min_price){
				reedem.r_uni_product_variants_main.valid_price = reedem.price;
				const _variant = {...reedem.r_uni_product_variants_main, is_get_combo: 0, r_uni_product_get_combo:null, id_combo:null, quantity: 1, is_reedem:1};
				
				this.$store.commit('transaction/cart/plus2Cart', _variant); //Tambah Ke Store
				let result = await this.checkStock(_variant);
				
				if(result.success){
					this.$store.commit('transaction/cart/setCheckReedem', 1);
					this.$store.commit('transaction/cart/setCheckMinReedem', reedem.min_price);
					this.synching(true, true);
				}
			}else{
				
				Swal.fire({
					title: "Peringatan!",
					text: "Total pembelian minimum "+reedem.min_price_rp,
					icon: "warning"
				});
			}
			
        },
		toRp(val) {
			return helpme.toRp(val);
		},
		plus(variant){
			if(!this.loadingCart){
				this.$store.commit('transaction/cart/plus', variant)
				// console.log(variant.quantity)
				this.synching(true, true);
			}
		},
		min(variant){
			
			if(variant.quantity == 1){
				this.removeVariant(variant);
			}
			if(!this.loadingCart){
				if(this.checkReedem == 1){
					Swal.fire({
						title: "Peringatan!",
						text: "Produk tebus murah terhapus",
						icon: "warning"
					});
					this.$store.commit('transaction/cart/removeReedem');
				}
				
				this.$store.commit('transaction/cart/min', variant)
				this.synching(true, true);
				
			}
		},
		removeVariant(variant){
			var textDelete = "";
			
			if(this.checkReedem == 1){
				textDelete = "Produk tebus murah akan terhapus";
			}
			
			if(!this.loadingCart){
				Swal.fire({
					text: textDelete,
					title: "Hapus dari keranjang ?",
					icon: 'warning',
					showCancelButton: true,
					showCloseButton: true,
					confirmButtonColor: '#364b7b',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Hapus',
					cancelButtonText: 'Tidak',
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.$store.commit('transaction/cart/remove', variant);
						this.synching(false);
					}
				});
			}
		},
		updateVariant(variant){
			
			let is_get_combo = variant.is_get_combo;
			let slug = variant.r_uni_product.slug;
			$("#name-product").html(variant.name_long);
			$("#price-product").html(variant.valid_price_rp);
			Popup.methods.show("updateVariant");

			//Ketika ganti variant , judul & harga diganti. Judul ambil dari kolom name_long
		},
		wishlistProduct(variant){
			if(!this.loadingCart){
				Swal.fire({
					text: "Hapus dari keranjang ?",
					icon: 'warning',
					showCancelButton: true,
					showCloseButton: true,
					confirmButtonColor: '#364b7b',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Hapus',
					cancelButtonText: 'Tidak',
				}).then(async (result) => {
					if (result.isConfirmed) {
						this.$store.commit('transaction/cart/remove', variant);
						this.synching(false);
					}
				});
			}
		},
		jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
		async synching(usingQueue=true,withLoading=false){
			if(this.authenticated){
				if(this.queue != null) clearTimeout(this.queue);
				if(usingQueue){
					this.queue = setTimeout(async () => {
						if(withLoading) this.loadingCart = true;
						await this.save();
						if(withLoading) this.loadingCart = false;
					}, 900);
				}else{
					if(withLoading) this.loadingCart = true;
					await this.save();
					if(withLoading) this.loadingCart = false;
				}
			}else{
				if(withLoading) this.loadingCart = false;
			}
		},
		async getLastSeen(){
            this.loadingLastSeen = true;
            await this.fetchLastSeen();
            this.loadingLastSeen = false;
        },
		async getCart(){
			await this.get();
		},
		async checkProduct(variant) {
			if(this.authenticated) {
				if(this.checkReedem == 1){
					Swal.fire({
						title: "Peringatan!",
						text: "Produk tebus murah terhapus",
						icon: "warning"
					});
					this.$store.commit('transaction/cart/removeReedem');
				}
				await this.$store.dispatch('transaction/cart/checkProduct', variant);
				await this.getCart();
			}
			else {
				this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }});
			}
		},
		async checkAll(e) {
			if(this.authenticated) {
				await this.$store.dispatch('transaction/cart/checkProductAll', e.target.checked);
				await this.getCart();
			}
			else {
				this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }});
			}
		},
		async getReedem(){
            
            await this.fetchReedem();
            // this.loading.voucher = false;
            $(".ol-checkout-reedemcarousel-list").slick({
                slidesToScroll: 1,
                slidesToShow: 3,
                dots: !1,
                autoplay: !0,
                autoplaySpeed: 5e3,
                arrows: false,
                pauseOnFocus: !1,
                infinite: !0,
                speed: 500,
                fade: !1,
                responsive: [{
                    breakpoint: 891,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            })
        },
	},
	watch:{
		catatan:function(value){
			this.$store.commit('transaction/cart/setNote', value);
		},
		// variants:function(value){
			// this.synching(true, true);
		// },
	},
	async mounted(){
		this.catatan = this.note;
		this.jqScrolling2top();
		// await this.getCart();
		// await this.synching(false);
		this.getLastSeen();
		this.getReedem();
	}
}
</script>

<style scoped lang="css">
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/css/cart.css';
	@import '../../assets/css/reedem.css';
	.ol-btn-disable{
        background-color: #aaa;
        border-color: #aaa;
        cursor: default;
        pointer-events: none;
    }

	.display-price-desktop{
		display: block;
	}
	.display-price-mobile{
		display: none;
	}

	@media (max-width: 768px) {
		.display-price-desktop{
			display: none;
		}
		.display-price-mobile{
			display: block;
		}
	}
</style>
